<script>
    /***
     Name: edit_dir
     Code: sa0ChunLuyu
     Time: 2021/11/5 16:44
     Remark: 编辑委托单表单分类
     */
    export default {
        props: ['info', 'update', 'create', 'edit'],
        data() {
            return {
                edit_info: {
                    "id": 0,
                    "name": "",
                    "pid": 0,
                    "type": 0,
                    "remark": "",
                }
            }
        },
        watch: {
            info: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            }
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            toCreate(type) {
                this.create(type);
            },
            mountedDo() {
                this.edit_info = JSON.parse(JSON.stringify(this.info))
            },
            createDo() {
                this.$sa0.post({
                    url: this.$api('创建委托单模板'),
                    data: {
                        name: this.edit_info.name,
                        pid: this.edit_info.pid,
                        type: this.edit_info.type,
                        remark: this.edit_info.remark,
                        content: [],
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.$router.push({
                                params: {
                                    id: response.data.id
                                },
                                query: {
                                    edit_type: 0
                                }
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            updateDo() {
                this.$sa0.post({
                    url: this.$api('修改委托单模板'),
                    data: {
                        id: this.edit_info.id,
                        name: this.edit_info.name,
                        pid: this.edit_info.pid,
                        type: this.edit_info.type,
                        remark: this.edit_info.remark,
                        content: [],
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.update()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            saveClick() {
                if (this.info.id === 0) {
                    this.createDo()
                } else {
                    this.updateDo()
                }
            },
            toEdit() {
                this.edit()
            },
            delClick() {
                layer.confirm('是否删除？', {
                    btn: ['确定', '取消'] //按钮
                }, () => {
                    this.$sa0.post({
                        url: this.$api('删除委托单模板'),
                        data: {
                            id: this.edit_info.id
                        }
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                layer.closeAll()
                                this.$router.push({
                                    params: {
                                        id: this.edit_info.pid
                                    }
                                })
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }, () => {
                });
            }
        }
    }
</script>
<template>
    <div>
        <div>
            <div>
                <div class="edit_title">{{ edit_info.id === 0 ? '创建新' : '编辑' }}分类</div>
                <div v-if="edit_info.id !== 0" style="margin-top: 20px; text-align: left; background: #FAFAFA; padding:10px 20px; height: 50px">
                    <a-button type="primary" @click="toCreate(0)">
                        添加分类
                    </a-button>
                    <a-button type="primary" @click="toCreate(1)" class="ml-4">
                        添加委托单模板
                    </a-button>
                    <a-button type="danger" @click="delClick()" class="ml-4" style="float: right">
                        删除
                    </a-button>
                    <div class="clearfix"></div>


                </div>
            </div>
            <div v-if="edit_info.id === 0 && edit_info.pid !== 0" style="margin-top: 20px; text-align: left; background: #FAFAFA; padding:10px 20px; height: 50px">
                <a-button type="primary" @click="toEdit()" class="ml-4" style="float: right">
                    返回
                </a-button>
            </div>
            <div class="form_wrapper">
                <a-form-model :label-col="{span: 6}" :wrapper-col="{span: 14}">
                    <div>
                        <a-form-model-item label="名称">
                            <a-input v-model="edit_info.name" class="w300"/>
                        </a-form-model-item>
                    </div>
                    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                        <a-button type="primary" @click="saveClick()" class="ml-4">
                            保存
                        </a-button>
                    </a-form-item>
                </a-form-model>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .form_wrapper{
        width: 1000px;
        margin-top: 20px;
        }

    .button_wrapper{
        height: 40px;
        width: 1000px;

        }

    .edit_title{
        font-size: 24px;
        font-weight: bold;

        }

    .w300{ width: 200px}
</style>
