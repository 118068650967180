<script>
import EditDir from './edit_dir.vue'
import EditItem from './edit_item.vue'

export default {
  components: {EditDir, EditItem},
  data() {
    return {
      expandedKeys: [],
      form_list: [],
      edit_type: 0,
      template_content: [],
      form_id: 0,
      form_info: {
        "id": 0,
        "name": "",
        "pid": 0,
        "type": 0,
        "remark": "",
      },
    };
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    toCreate(type) {
      this.edit_type = 1
      this.edit_info = type
      this.form_info = {
        "id": 0,
        "name": "",
        "pid": this.form_id,
        "type": type,
        "remark": ""
      }
    },
    toEdit() {
      this.edit_type = 0
      this.mountedDo();
    },
    updateData() {
      this.mountedDo()
    },
    formClick(e) {
      let id = e[0] ? e[0] : this.form_id
      if (Number(id) === Number(this.$route.params.id)) {
        this.mountedDo()
      } else {
        this.$router.push({
          params: {
            id: id
          },
          query: {
            edit_type: 0
          }
        })
      }
    },
    mountedDo() {
      this.form_id = Number(this.$route.params.id ? this.$route.params.id : 0)
      this.edit_type = Number(this.$route.query.edit_type ? this.$route.query.edit_type : 0)
      this.getFormList()
      this.getFormInfo()
    },
    getFormInfo() {
      if (this.form_id === 0) {
        if (this.edit_type === 0) {
          this.form_info = {
            "id": 0,
            "name": "",
            "pid": 0,
            "type": 0,
            "remark": "",

          }
        } else {
          this.$router.push({
            params: {
              id: this.form_id
            },
            query: {
              edit_type: 0
            }
          })
        }
      } else {
        this.$sa0.post({

          url: this.$api('根据id获取委托单详情'),

          data: {
            id: this.form_id,

          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.form_info = response.data.template
              this.expandedKeys = ((this.form_info.template_ids).toString()).split(',').map((item) => {
                return Number(item)
              })
              this.template_content = response.data.template_content.map((item) => {
                if (item.form_word.type !== 0) {
                  let con = item.form_word
                  switch (con.type) {
                    case 1:
                    case 5:
                    case 6:
                    case 7:
                      con.content_turn = con.content;
                      break;
                    case 2:
                    case 3:
                    case 4:
                      con.content_turn = JSON.parse(con.content);
                      break;
                  }
                  item.form_word = con
                  return item
                }
              })


            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }
    },
    createDir() {
      this.$router.push({
        params: {
          id: 0
        }
      })
    },
    getFormList() {
      this.$sa0.post({
        url: this.$api('获取委托单分级列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.form_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    }
  },
};
</script>
<template>
  <div class="wraper">
    <div class="wrap-mainer">
      <div class="bgc sidebar">
        <div class="samplelefttit">
          委托单模板列表
        </div>
        <div class="sidebarmain">
          <a-tree :tree-data="form_list" :expandedKeys.sync="expandedKeys"
                  :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="true"
                  @select="formClick">
            <a-icon slot="icon" type="carry-out"/>
          </a-tree>
        </div>
        <div class="addbutton">
          <div class="addbuttonbtn" @click="createDir" >
            添加分类
          </div>
        </div>
      </div>
      <div class="wrap-container">
        <!--   创建编辑分类-->
        <div class="createsmaplewrap">
          <EditDir :create="toCreate"
                   :edit="toEdit"
                   :update="updateData"
                   v-if="Number(form_info.type) === 0"
                   :info="form_info"></EditDir>
          <EditItem :create="toCreate"
                    :content="template_content"
                    :edit="toEdit"
                    :update="updateData"
                    v-if="Number(form_info.type) !== 0"
                    :info="form_info"></EditItem>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bgc {
  margin-right: 20px;
  border: 1px solid #eeeeee;
}

.samplelefttit {
  background: #f9f9f9;
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
  font-weight: bold
}

.addbutton {
  width: 100%;
  padding: 20px;
  text-align: center;
  padding: 0;
}

.createsmaplewrap {
}

.wraper{ width: 100%; height: 100%; display: flex; flex-direction: row}
.wrap-mainer{ flex: 1;display: flex;}
.sidebar{ width: 250px;  display: flex; flex-direction: column}
.sidebarmain{ flex: 1; overflow-y: auto}
.wrap-container{ flex:1; overflow-y: auto}
.addbutton{ width: 100%; text-align: center; bottom: 0; height: 40px; background: #1890ff}
.addbuttonbtn{ width: 100%; color: #ffffff; line-height: 40px; cursor: pointer; height: 40px}
.bgc{ margin-right: 20px; border: 1px solid #eeeeee;}
.samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }
.createsmaplewrap{ width: 1000px; }
.createsmaple{ width: 1000px; display: flex; justify-content: space-between}
.createsmapletit{ font-size: 20px; font-weight: bold}

</style>
